





















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import ElectionForm from './election-form.vue';
import History from '@/views/_shared/history.vue';

class PageElectionRequest extends PageRequest {
    state: number = null;
    date_from: string = new Date().toISOString().substr(0, 4) + '-01-01';
    date_to: string = new Date().toISOString().substr(0, 10);
}

@Component({
    components: { ListView, FormBase, ElectionForm, History }

})
export default class Elections extends ListBase {
    entity: string = 'election';
    pagerequest: PageElectionRequest = new PageElectionRequest();
    filterForm: boolean = true;
    hisModalShow: boolean = false;
    viewShow: boolean = false;
    sState: any = Util.abp.list.getCodeList('E_ELECTION');

    filterItems = [
        this.gItem({ type: 'date', prop: 'date_from', label: 'Votaciones Desde', size: 2, hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'date', prop: 'date_to', label: 'Hasta', size: 2, hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'object', prop: 'state', label: 'Estado', size: 3, generalType: 'E_ELECTION', hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'spacer', size: 5 }),
    ]

    columns = [
        this.gCol('number_formated', 'Codigo', 'text'),
        this.gCol('description', 'Descripcion', 'text'),
        this.gCol('voting_date', 'Fecha de Votaciones', 'date'),
        this.gCol('nro_applicants', 'Nro de Postulantes', 'text'),
        this.gColO({ type: 'chip', value: 'state_id', text: 'Estado', color: 'extra', generalType: true, onclick: this.showHistory }),
        this.defaultActions('view|edit|delete', 130)
    ]

    get exluddeIds() {
        let ids = [];
        let ids2 = [];
        this.$store.state.election.list.forEach(item => {
            if (!item.valid)
                ids.push(item.id);
            if (item.enrolled_students > 0 || item.certified || item.state_id == this.sState.CE)
                ids2.push(item.id);
        });

        return { view: ids, delete: ids2 };
    }

    showHistory(item) {
        this.$store.commit('election/view', item);
        this.hisModalShow = !this.hisModalShow;
    }        

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    view() {
        let item = this.$store.state.election.editModel;
        this.$router.push({ name: 'electionview', params: { id: item.id } });
    } 

    async afterSave() {
        let list: any = this.$refs.listView
        if (list != undefined)
            await list.getpage();
    }

                         
    created() {
    }

    mounted() {
    }
}