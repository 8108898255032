






















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Election from '@/store/entities/elections/election';
import Applicants from './applicants.vue';
import Questions from './questions.vue';

@Component({
    components: { ModalView, FormBase, Applicants, Questions },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.election = Util.extend(true, {}, this.$store.state.election.editModel);                     
                obj.initForm();
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            }
        }
    }
})

export default class ElectionForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    election: Election = new Election();
    modalSetting: any = {
        entity: "election",
        title: 'Eleccion',
        width: "1200px",
        showForm: false,
    };
    
    items = [
        this.gItem({ type: 'object', prop: 'type_id', label: 'Tipo de Elecciones', size: 8, required: true, generalType: 'T_ELECTION' }),
        this.gItem({ type: 'spacer', size: 4 }),
        this.gItem({ type: 'text', prop: 'description', label: 'Descripcion', size: 8, required: true }),
        this.gItem({ type: 'date', prop: 'voting_date', label: 'Fecha de Votaciones', required: true, size: 4 }),
        this.gItem({ type: 'switch', prop: 'with_schedule', label: 'Habilitar Limite de Horario', size: 6, onchange: this.changeSche }),
        this.gItem({ type: 'spacer', prop: 'spacer', size: 6, visible:true }),
        this.gItem({ type: 'time', prop: 'start_hour', label: 'Hora de Incio', hint: 'HH:MM', persistentHint: true, size: 3, visible: false }),
        this.gItem({ type: 'time', prop: 'end_hour', label: 'Hora de Fin', hint: 'HH:MM', persistentHint: true, size: 3, visible: false }),
        this.gItem({ type: 'text', prop: 'notes', label: 'Observaciones y Notas', size: 12 }),
        this.gItem({ type: 'divider', subheader: 'Configuracion', size: 12 }),
        this.gItem({ type: 'select', prop: 'voter_type', label: 'Quienes pueden Votar', options: 'voter_options', size: 6, clearable:false }),
        this.gItem({ type: 'object', prop: 'security_level_id', label: 'Nivel de Seguridad de las Elecciones', generalType: 'SECURITY_LEVEL', size: 6 }),
        this.gItem({ type: 'number', prop: 'nro_options', label: 'Cantidad de Opciones a Elegir', prefix: '#', size: 6 }),
        this.gItem({ type: 'number', prop: 'nro_winners', label: 'Cantidad de Ganadores', prefix: '#', size: 6 }),
    ]

    get isEditing() {
        return this.election.id > 0;
    }

    get loading() {
        return this.$store.state.election.loading;
    }

    get typeCode() {
        let item = Util.getTypeValueById(this.election.type_id);
        if (item == null) return '';
        return item.code;
    }

    changeSche(value) {
        this.setItem(this.items, 'spacer', 'visible', !this.election.with_schedule);
        this.setItem(this.items, 'start_hour', 'visible', this.election.with_schedule);
        this.setItem(this.items, 'end_hour', 'visible', this.election.with_schedule);
    }

    async getLastNumber() {
        let number = await this.$store.dispatch({
            type: 'election/getLastNumber'
        });
        this.election.number_formated = number;
        this.election.number = parseInt(number);
    };

    initForm() {
        this.$store.commit('election/setApplicants', this.election.applicants);
        this.setItem(this.items, 'type_id', 'readonly', false);

        if (this.election.id > 0) {
            this.setItem(this.items, 'type_id', 'readonly', true);
        } else
            this.getLastNumber();

        this.changeSche(0);
    }
    
    async save(onlySave = false) {
        if ((this.$refs.entityForm1 as any).validate()) {
            let model = Util.extend(true, {}, this.election);
            model.applicants = this.$store.state.election.applicants;

            let newModel = await this.$store.dispatch({
                type: 'election/' + (this.election.id > 0 ? 'update' : 'create'),
                data: model
            });

            if (onlySave)
                return newModel;
            else {
                this.$emit('save-success');

                this.cancel();
                if (this.election.id > 0)
                    Util.abp.notify.success(this, this.L('Eleccion actualizada!'));
                else
                    Util.abp.notify.success(this, this.L('La Eleccion ha sido registrada!'));
            }
        }
    }

    async confirm() {
        this.$swal({
            title: '&iquest;Est&aacute; seguro?',
            html: 'Al confirmar ya no podra realizar cambios',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, &iexcl;Confirmar!',
            cancelButtonText: '&iexcl;No!',
            showCloseButton: true,
            showLoaderOnConfirm: true
        }).then(async (result) => {
            if (result.value) {
                await this.$store.dispatch({
                    type: 'election/confirm',
                    data: { id: this.election.id }
                });
                this.$emit('save-success');
                this.cancel();
                this.$swal(this.L('La Eleccion ha sido Confirmada'), this.L('Ya no podras editar los datos, queda pendiente el inicio de la eleccion!'), 'success');

            }
        });
    }

    cancel() {
        this.$emit('input', false);
        (this.$refs.entityForm1 as any).reset();
    }

    async mounted() {
        await this.$store.dispatch({
            type: 'position/getAllActive'
        });
    }

    created() {
    }
}
